import React from "react";
import Link from "./Link";
import { cn, buildImageObj, buildSrcSet } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { useLazyImage } from "../lib/lazyload";

import styles from "./poster-preview.module.css";

const sizes = [{ width: 400 }, { width: 600 }, { width: 1000 }, { width: 1400 }];

function PosterPreview(props) {
  const imageUrl = imageUrlFor(buildImageObj(props.mainImage));

  useLazyImage();

  return (
    <article className={styles.root}>
      <Link className={styles.link} to={`/poster/${props.slug.current}`}>
        {props.mainImage && props.mainImage.asset && (
          <figure className={styles.previewImage}>
            <img
              data-srcset={buildSrcSet(imageUrl, sizes)}
              data-sizes="(max-width: 48em) 50vw, (max-width: 70em) 30vw, 25vw"
              alt={props.mainImage.alt}
              loading="lazy"
              className="lazy"
            />
          </figure>
        )}
        {/* <div className={styles.titleContainer}>
          <h2 className={styles.title}>{props.title}</h2>
          <span className={styles.categories}>{prepCategories(props.categories)}</span>
        </div> */}
      </Link>
    </article>
  );
}

export default PosterPreview;
