import React from "react";
import { cn } from "../lib/helpers";
import PageIntro from "./page-intro";

import gridStyles from "./grid.module.css";
import PosterPreview from "./poster-preview";

function PosterPreviewGrid({ page }) {
  return (
    <div className={cn(gridStyles.container, gridStyles.layoutFourCols)}>
      {page._rawDescription && (
        <div className={gridStyles.block}>
          <PageIntro content={page._rawDescription} />
        </div>
      )}

      {page.posters &&
        page.posters.map(poster => (
          <div className={gridStyles.block} key={poster.id}>
            <PosterPreview {...poster} />
          </div>
        ))}
    </div>
  );
}

PosterPreviewGrid.defaultProps = {
  page: {}
};

export default PosterPreviewGrid;
